@import "antd/dist/antd.css";

.main-page {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

#dropdown {
  width: 200px;
  background-color: white;
  border: none;
  padding: 10px;
  color: black;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dropdown {
  width: 200px;
  border: none;
  color: black;
  margin-bottom: 10px;
  margin-top: 10px;
}


.berth-details {
  width: 80vw;
  height: auto;
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
  margin: auto;
  background: #419794;
  border: 5px solid #3d3c3c;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 69%);
  border-radius: 8px;
}

.Dc-details-container {
  text-align: center;
}

.Dc-details-berth-slots {
  display: flex;
  justify-content: space-around;
  line-height: 6px;
  color: white;
  font-size: 14px;
}

.city-color {
  color: #FCB713;
  font-size: 20px;
}

.date {
  font-size: 16px;
}

.location {
  margin-right: 10px;
}

.data-color {
  color: #e0eeee;
}

.booking-doors {
  width: 75vw;
  background-color: #e5e5e5;
  border: 1px solid black;
  height: 160px;
  margin: auto;
  margin-bottom: 70px;
}

.warehouse {
  display: flex;
  margin: 10px;
}

.not-booked {
  display: flex;
  height: auto;
}

.not-booked img {
  margin-left: 3rem;
  justify-content: space-around;
}

.firstImage {
  margin-top: 10px;
  justify-content: flex-start;
}

.image-click {
  background-color: yellow;
  border: 2px solid black;
}

.booked {
  width: 160px;
  text-align: center;
  margin-top: 10px;
}

.booked p {
  color: #ca1607;
}

.rightArrow {
  width: 170px;
  position: relative;
  margin-top: -34px;
  z-index: -1;
  margin-left: -13px;
}

.slots {
  margin-left: 12%;
  margin-bottom: -15px;
  display: flex;
}

.slotButtons button {
  background-color: white;
  border-top-right-radius: 90px;
  width: 150px;
  border: white;
}

.tabs {
  margin-top: 2rem;
}

.each-tab {
  font-size: 1rem;
  padding: 1rem 2rem;
  border-top-right-radius: 90px;
  background-color: white;
  border: 1px solid white;
}


.newOrder {
  width: 100vw;
  display: flex;
}

.newOrder-image {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-left: 37%;
}

.newOrder-image img {
  width: 50px;
  height: 50px;
}

.newOrder-image p {
  color: #46bf7e;
}

.container {
  width: 100%;
}

.container-margin {
  margin-top: 20px;
}

.wheeler-details {
  width: 100vw;
  margin-top: 20px;
}

.wheelers {
  width: 90px;
  height: 100px;
  background-color: white;
  margin-bottom: -17px;
  position: relative;
}



.all-details {
  width: 75%;
  position: relative;
}


.order-details-width {
  font-size: 20px !important;
}

.address-text-field {
  font-size: 20px;
  margin: 10px;
  margin-left: 10px !important;
  width: 89.5%;
  font-weight: 700 !important;
}

.factory-main-div {
  margin-left: 5px;
}

.factory-address-text-field-div{
  width: 100%;
  margin-top: 10px;

}

.factory-address-text-field {
  font-size: 20px;
  margin-left: 20px !important;
  width: 96%;
  font-weight: 700 !important;
}


.editOrder p {
  margin: 5px;
}

.input {
  outline: none;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: 1px solid black;
  width: 180px;
}

.vendor-inputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.all-details {
  width: 100% !important;
}

.vendor-inputs label {
  color: #FCB713 !important;
  opacity: 1 !important;

}

.align-vehicle {
  display: flex;
  flex-wrap: wrap;
}

.deliveryTruck {
  width: 60px;
}

.deliveryTruckOne {
  margin-top: 2rem;
}

.color-label {
  color: #FCB713 !important;
  font-weight: 700;
}

.dateDispatch {
  margin-top: 1.3rem;
  margin-left: 10px;
  height: 35px !important;
  width: 197px !important;
  border: 0.5px solid black !important;
  padding-left: 5px;
  display: block !important;
}
.timepicker-div {
  margin-top: 7px;
}
.dateDelivery {
  margin-top: 1.3rem;
  margin-left: 10px;
  height: 35px !important;
  width: 197px;
  border: 0.5px solid black !important;
  padding-left: 5px;
  display: block !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.all-driver-details-container {
  display: flex;
  padding: 20px 10px;
}

.driver {
  display: flex;
}

.driverInput {
  display: flex;
  margin-top: -20px;
  margin-left: 7px;
}

.vendor-date-given {
  margin-top: -4px;
  margin-left: 10px;
}

.driverInputMargin {
  display: flex;
  margin-top: -5px;
  margin-left: 7px;
}

.add-driver-outline {
  text-align: center;
  margin-top: 22px;
  display: flex;
  align-items: center;
}

.receiver-new-order-comp {
  margin-top: 30px;
}

.receiver-new-order-comp label {
  color: #FCB713 !important;
  opacity: 1 !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}

.receiver-new-order-comp h5 {
  margin-top: 20px;
  margin-left: 10px;
}

.receiver-margin {
  margin-top: 40px;
}

.enter-tracking-link-div {
  margin-left: 10px;
  margin-top: 15px;
}

.enter-tracking-link-div p {
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 0px;
}

.enter-tracking-link-div div input {
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1.2px solid #419794;
  border-radius: 2.5rem;
  width: 200px;
  padding: 5px 15px;
}

.shipFrom img {
  margin-right: 5px;
  width: 15px;
  height: 20px;
}

.vahan-header {
  display: flex;
  flex-direction: end;
  padding: 20px;
  justify-content: space-between;
  padding-bottom: 0px;
}

.vahan-header-upload {
  border: none;
  background: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  color: #16c612;
  margin-left: 10px !important;
  margin-top: 25px !important;
  width: 200px;
}

.vahan-header-status {
  display: flex;
  justify-content: space-around;
  padding-left: 65px;
}


.container-ship {
  display: flex;
  width: 100%;
  background-color: white;
}

.comment-container {
  width: 100%;
  height: 650px;
  display: inline-block;
  justify-content: flex-end;
  margin-top: 50px;
}

.comment-container p {
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: 900;
}

.comment-container img {
  text-align: center;
}

.delivery-comments {
  display: flex;
  justify-content: flex-end;
  margin-right: 250px;
}

.delivery-comments img {
  width: 25px;
  height: 30px;
  margin-right: 10px;
}

.delivery-comments p {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
}

.commentBox {
  width: 600px;
  height: 630px !important;
  background-color: #f6f8fb;
  margin-right: 3rem;
}

.commentBox1 {
  margin: auto;
  position: relative;
}

.comments {
  width: 90%;
  height: 200px;
  background-color: #ffffff;
  margin: auto;
  margin: 20px;
  margin-top: 320px;
  border-style: none;
  padding: 5px;
  opacity: 0.9;
  position: relative;
}

.button {
  width: 100px;
  text-align: center;
  padding: 5px;
  background-color: teal;
  color: #ffffff;
  margin: 20px;
  margin-top: 0px;
  margin-right: 40px;
}

.mode {
  background-color: #d8d8d8;
  height: 70px;
}

.events li {
  display: flex;
  color: #999;
}

.events time {
  position: relative;
  padding: 0 1.5em;
}

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  border-radius: 50%;
  background: #fff;
  border: 1px #ccc solid;
  width: 0.8em;
  height: 0.8em;
}

.events span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  border-left: 1px #ccc solid;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  margin: 1em;
  width: 50%;
}

.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}

.button-tabs {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.bloc-tabs {
  display: flex;
  margin-left: 15%;
}

.tabs {
  text-align: center;
  width: 50%;
  background: #F8F8F8;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  border-top-right-radius: 100px;
  width: 150px;
  padding-top: 20px;
  padding-right: 15px;
}


.active-tabs {
  background: white;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
}

button {
  border: none;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  background: white;
  padding: 20px;
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}


.label {
  color: #FCB713 !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  font-size: 15px !important;
}


.enter-vehicle {
  margin-left: 0px !important;
  color: #09101D;
}

.vehicle-labels {
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 25.5px;
}

.source {
  margin-top: 10px !important;
}

.drivers {
  width: 197px !important;
  margin-left: 30px !important;
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 25.5px;
  margin-top: 10px !important;
}

.drivers label {
  line-height: 25.5px;
}

.driver-image {
  margin-top: 10px;
}

.contacts {
  margin-top: 1px;
}

.slotFrom-dropdown {
  font-weight: 700 !important;
  font-size: 20px !important;
}

.modal111 {
  justify-content: center;
}

.modal123 {
  height: 500px !important;
  justify-content: center;
}

.add-logistics {
  text-align: center;
  background-color: #d8d8d8;
  padding: 7px;
}

.add-logistics h4 {
  font-weight: 700;
  margin-top: 10px;

}


.new-label-dispatch {
  font-size: 11px !important;
  width: 200px !important;
  font-weight: 700 !important;
  margin-right: 30px !important;
  margin-bottom: -10px;
  line-height: 26px;
}

.given_date {
  line-height: 5px !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.delivery-margin-top {
  margin-top: 10px !important;
}

.dispatch-label {
  position: relative;
}

.wheeler-item-font {
  font-size: 13.5px !important;
}

.order-details-submit-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.loaded-truck-radio-btns-div {
  display: flex;
  margin-top: 15px;
  text-align: left;
  margin-left: 0px;
}

.loaded-truck-radio-btns-div div {
  width: 235px;
}

.loaded-truck-radio-btns-div div label {
  padding-left: 5px;
}

@media (min-width:1024px) and (max-width:1380px) {
  .vahan-header-upload {
    background: #fff;
    border: none;
    border-radius: 5px;
    color: #16c612;
    -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
    margin-top: 25px !important;
    width: 200px;
    margin-left: 10px;
    margin-right: 30px;
  }

}