.head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    height: 73px;
}
.head img{
    margin-left: 64px;
    width: 90px;
    height: 70px;
}
.head h3 span{
    color: #419794;

}
.head h3{
    color: #FCB713;
    letter-spacing: 0.2px;
    margin-top: 26px;
    font-family: 'Rammetto One';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin-left: 2rem;
}
.avatar{
    display: flex;
    margin-top: 19px;
    margin-right: 20px;
}
#user-info{
    color: black;
    margin-top: -0.6rem;
}
.nav{
    background-color:#419794;
    margin-top: 0.8rem;
}
.nav li{
    display: flex;
    /* justify-content: space-around; */
    justify-content: flex-start;
    align-items: center;
    margin-left: 2.6%;
}
.nav li ul{
    margin-top:13px;
    cursor: pointer;
    padding: 5px;
    font-weight: 600;
    font-size: 16px;
    margin: 10px 25px;
   line-height: 24px;
}
.nav li ul span{
    margin-right: 16px;
}
.nav li a{
    text-decoration: none;
    color:#FFFFFF;
}
.nav li ul:hover{
    background: #FCB713;
    border-radius: 3px;
    /* background: #FCB713;
    border-radius: 5px;
    padding: 10px; */
    
}
.backgroundHeader{
    background: #FCB713;
    border-radius: 3px;
    

}
/* .background-inactive{

} */
.user-icon{
    width: 40px !important ;
    height: 40px !important ;
}
.logout{
    cursor: pointer;
    margin-left: 10px;
}
.username{
    color: #52575C;
    font-family: 'Source Sans Pro';
    margin-top: -4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.myprofile{
    margin-top: -15px;
    color: #272833;
    font-weight: 400;
    font-size: 13px;
}

.adding-vendor-photo {
    /* padding: 10px 0 0 15px; */
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
}
.adding-vendor-photo h3{
    margin-bottom: 0;
    color: #FCB713;
    /* color: black; */
    margin: auto;
    font-size: 35px;
    font-weight: 600;
}

.profile-photo { 
    margin-right: 50px;
    position: relative;
}

.profile-photo-first-img {
    width: 130px;
    height: 130px;
}

.profile-photo-second-img {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom:0;
    margin-right: -10px;
}

.details-user-div {
    padding: 35px 0px 0px 15px;
}

.name-number-email-div {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 20px;
    margin-top: 10px;

}

.name-div {
    width: 200px;

}

.name-div h6{
    color:#FCB713;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.name-div p {
    color: black;
    font-size: 14px;
    font-weight: 500;
}

.ant-modal-body {
    padding: 24px 24px 0 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}


.address-h6{
    color:#FCB713;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}
.address-p {
    color: black;
    font-size: 14px;
    font-weight: 500;
}