

.logindetails{
  
    background: #FFFFFF;
    justify-content: center;
    border-radius: 50px;
    padding-bottom: 2%;
}
.logindetails Input{
    padding: 0px 15px;
    max-width: 279px;
    height: 40px;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.logindetails Button{
  min-width:279px;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    background-color: #419794;
    color: #FFFFFF;
    font-family: 'Poppins' !important;
margin-top: 15px;
    
}
.login-right{
    margin: 1rem 2rem 0rem 3rem;
}
.loginName{
    margin-top: 1rem;
}
.loginName h1{
    font-size: 1.3rem;
    font-weight: 1000;
    color: #FCB713;
    font-family: 'Rammetto One';
    font-weight: 400;
    letter-spacing: 0.1rem;
}
.loginName span{
    color:#419794
}
.sign{
    font-size: 0.9rem;
    color: #8392AB;
    font-weight: 400;
    font-size: 14px;
    margin: 2rem 0rem;
    max-width: 279px;
}
.loginName h3{
    width: 94px;
    height: 54px;
    left: 0px;
    top: 227.35px;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    
    letter-spacing: -0.995745px;
    
    background: linear-gradient(135deg, #419794 0%, #FCB713 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    
margin-bottom: 1.5rem;
}

.brand-logo img{
    margin-left:64%;
    width: 95px;
}
.login{
    background: url(../../images/loginBackground.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.loginImage {
width: 70%;
display:block;
}
.vahan-image{
    width: 100%;
    display: block;
    object-fit: none;
}

   
    
.email{
    color: #8A92A6;
    font-weight: 500;
    font-size: 12px;
}
.login-button{
    max-width: 279px !important;
}

.forget-password {
    color: #8A92A6;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
}

.ant-modal-footer {
    border-top: none;
    padding-bottom: 2rem;
    padding-right: 24px
}

.valid-email-input {
    width: 80%;
    margin-right: 10px;
}

.enter-email-address-div {
    padding: 20px;
}

.enter-email-address-div h5 {
    font-size: 20px;
    font-weight: 600;
}

.input-btn-div {
    display: flex;
}

.input-btn-div Button {
    background-color:#419794;
    color: white;
}