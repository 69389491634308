@import "antd/dist/antd.css";

.orders-page {
  width: 100%;
  height: auto;
}

.main-page {
  display: flex;
  justify-content: space-around !important;
  margin: auto !important;
}

#dropdown {
  width: 200px;
  background-color: white;
  border: none;
  padding: 10px;
  color: black;
  margin-bottom: 10px;
  margin-top: 10px;
}

.calendar-dropdown {
  width: 200px;
}

.select_dropdown{
  display: none !important;
}
.orderview-selectDc {
  width: 200px;
  background-color: white;
}

.spacing-margin {
  margin-right: 20px;
}

.working-hours-div {
  margin-right: 110px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.datePicker {
  width: 200px;
  background-color: white;
  border: 0.1px solid rgb(206, 205, 205);
  padding: 3px;
  color: black;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.booking-berth {
  width: 100%;
}

.berth-container {
  width: 100%;
  height: auto;
  filter: drop-shadow(1px 1px 1px #3d3c3c) 
          drop-shadow(1px 0px 0px #3d3c3c)
          drop-shadow(-5px 1px 1px #3d3c3c) 
          drop-shadow(0px -1px 0px #3d3c3c)
          drop-shadow(1px 1px 0px #3d3c3c) 
          drop-shadow(5px -1px 1px #3d3c3c)
          drop-shadow(-1px 2px 0px #3d3c3c) 
          drop-shadow(5px 5px 5px #3d3c3c);
}

.berth-details {
  width: 80vw;
  height: auto;
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
  margin: auto;
  background: #419794;
  border: 5px solid #3d3c3c;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 69%);
  border-radius: 8px;
}
.Dc-details-container {
  text-align: center;
}
.Dc-details-berth-slots {
  display: flex;
  justify-content: space-around;
  line-height: 6px;
  color: white;
  font-size: 14px;
}
.city-color {
  color: #FCB713;
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
}
.date {
  font-size: 5px;
}
.location {
  margin-left: 3.9rem;
  padding-right: 5px;
}
.data-color {
  color: #ffffff;
  font-weight: 500;
}
.time {
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 22px;
}
.working-hours {
  letter-spacing: 0.1px;
  line-height: 20px !important;
  font-weight: 500;
  font-size: 14px;
}
.slot_from {
  line-height: 10px !important;
  font-size: 14px;
  margin-left: -13px;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}

.total-berths {
  line-height: 24px;
  letter-spacing: 0.0902778px;
}

.berths-span {
  margin-left: 20px;
}

.contact-name-right {
  margin-left: 1.2rem;
}
.contact-details-right {
  margin-left: 0.9rem;
}

.booking-doors {
  width: 75vw;
  background-color: #e5e5e5;
  border: 2px solid #e5e5e5 !important;
  height: 200px !important;
  margin: auto;
  margin-bottom: 70px;
  box-shadow: 0px 1px 4px 1px rgb(87, 86, 86);
}

.warehouse {
  display: flex;
  justify-content: space-around !important;
  flex-wrap: nowrap;
  justify-content: flex-start !important;
  cursor: pointer;
}

.element {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}
.warehouse::-webkit-scrollbar {
  display: none;
}
.warehouse p {
  text-align: center;
  font-weight: bolder;
  font-size: 1.2rem;
  text-transform: capitalize;
}
.warehouse-doors {
  margin-right: 6rem;
  margin-left: 2rem;
}
.not-booked img {
  margin-left: 4rem;
}
.firstImage {
  margin-left: 20px;
}

.background {
  background-image: url("../../images/arrow.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 97px;
  margin-top: -15px;
  
}

.image-click {
  background-color: yellow;
  border: 2px solid black;
}
.booked {
  color: #d04337;
}

.booked p {
  color: #ca1607;
}
.rightArrow {
  width: 170px;
  position: relative;
  margin-top: -34px;
  z-index: -1;
  margin-left: -13px;
}

.slots {
  margin-left: 12%;
  margin-bottom: -15px;
  display: flex;
}

.order-booked-buttons {
  display: flex;
}
.slotButtons button {
  background-color: white;
  border-top-right-radius: 90px;
  width: 150px;
  border: white;
}
.tabs {
  margin-top: 2rem;
}
.each-tab {
  font-size: 1rem;
  padding: 1rem 2rem;
  border-top-right-radius: 90px;
  background-color: white;
  border: 1px solid white;
}

.sravan {
  justify-content: space-between;
}
.newOrder {
  display: flex;
  display: block;
}

.image-none {
  display: none;
}

.newOrderImage {
  display: flex;
  justify-content: flex-end;
}
.newOrderImage img {
  width: 41px;
  height: 41px;
}

.newSlot {
  color: #01a94d;
  font-weight: 700;
  text-align: center;
  margin-left: 10px;
  font-size: 14px;
  line-height: 21px;
  margin-top: 12px;
}

.container-buttons {
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: space-between;
}
.order-buttons {
  margin: auto;
}
.container {
  width: 90% !important;
  background-color: white;
}
.order-buttons-body {
  width: 100vw;
}
.container-margin {
  margin-top: 20px;
}
.wheeler-details {
  width: 100vw;
  margin-top: 20px;
}

.wheelers {
  width: 90px;
  height: 100px;
  background-color: white;
  margin-bottom: -17px;
  position: relative;
}

.buttons-element {
  background: none;
}

.comment-container {
  width: 100%;
  display: inline-block;
  justify-content: flex-end;
  margin-top: 30px;
}
.comment-container p {
  text-align: center;
  color: black;
}
.commentBox {
  width: 40%;
  height: 650px;
  background-color: #f6f8fb;
  float: right;
}
.commentBox1 {
  margin: auto;
}
.comments {
  width: 90%;
  height: 200px;
  background-color: white;
  margin: auto;
  margin: 20px;
  margin-top: 320px;
  border-style: none;
  padding: 5px;
  opacity: 0.9;
  position: relative;
}
.button {
  width: 100px;
  text-align: center;
  padding: 5px;
  background-color: teal;
  color: white;
  float: right;
  margin: 20px;
  margin-top: 0px;
}

.editing {
  height: 450px;
  background: #ffffff;
}
.mode {
  background-color: #d8d8d8;
  height: 80px;
  padding: 10px;
  text-align: center;
  margin-top: 5px;
}

.mode2 {
  margin-top: 2.3rem;
}

.mode2 h5 {
  text-align: center;
  font-weight: bolder;
  font-size: 16px;
}
.editButton {
  margin-right: 15px;
  height: 40px;
  font-size: 1.3rem;
  width: 130px;
}

.selectDate {
  border-top: none;
  border-left: none;
  border-right: none;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  border-left: 1px #ccc solid;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  margin: 1em;
  width: 50%;
}
.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}

.button-tabs {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.changing-tabs {
  padding-top: 20px;
}

.bloc-tabs {
  display: flex;
  margin-left: 16%;
}

.tabs {
  text-align: center;
  width: 50%;
  background: #f8f8f8;
  border: 1px solid rgba(235, 238, 243, 0.9);
  box-shadow: inset 0px -1px 0px #ffffff;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  border-top-right-radius: 100px;
  border-top-left-radius: 15px;
  height: 130px;
  padding-right: 20px;
  overflow: hidden;
}

.tabs:not(:last-child) {
  border: 2px solid rgba(128, 128, 128, 0.075);
}

.active-tabs {
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: inset 0px -1px 0px #ffffff !important;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  border-bottom: none !important;
}

.wheeler {
  color: #419794;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.1px;
  font-weight: 400 !important;
}

.wheeler-slot {
  color: #336cfb;
  font-size: 15px !important;
  line-height: 25px !important;
  letter-spacing: 0.1;
  font-weight: 400 !important;
}
.wheeler-vendor {
  color: #000000;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.1px;
  font-weight: 400 !important;
}
.openElement {
  color: #419794;
  font-size: 20px !important;
  font-weight: 700 !important;
  letter-spacing: 0.1;
  line-height: 30px !important;

}
.wheeler-slot-available {
  color: #336cfb;
  font-size: 16px !important;
  line-height: 25px !important;
  letter-spacing: 0.1;
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

.vahan {
  display: none;
}

.no-order-found {
  color: red;
  line-height: 31px;
  font-weight: 700 !important;
}

.clue-message{
  color: #4b4a4a;
  font-weight: 700 !important;
  line-height: 26px !important;

}

.total-berths-colon {
  margin-left: 2px !important;
}

.drawkitTransport {
  text-align: center;
  margin-top: 45px;
}
.drawkitTransport img {
  width: 298.83px;
  height: 131.89px;
}
.transport-p{
  text-align: center;
}
.transport-p {
  height: 42px;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  color: #000000;
  text-align: center;
  margin-top: 20px;
}

