
.horizontal{
    margin-left: -20px;
    width: auto;
    margin-right: -20px;
}

.filter-bottom{
    display: flex;
    
}
.each-drop{
    margin-left: 30px;
}

.input-field{
   width: 110%;
   margin-left: 6rem;
   height: 50px !important;
}
.button-sectionOne{
    margin-left: 38px;
}
.date{
    height: 48px !important;
    width: 100%;
    font-size: 1.5rem !important;
    border-radius: 6px !important;
    position: absolute !important;
}
.calendar-icon{
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
}
.calendar-icon-enddate{
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
}
.dateOne{
    height: 48px !important;
    width: 100%;
    font-size: 1.5rem !important;
    border-radius: 6px !important;
    position: absolute !important;
}
.order-status-dropdown{
    display: flex;
}
.date-filter-dropdown{
    display: flex;
   max-width: 95%;
}
.search-input{
    height: 45px !important;
    border-radius: 5px;
    padding-right: 45px;
}
.input-container {
    justify-content: start;
    padding: 0;
}
.input-wrapper {
    width: 90%;
    position: relative;
}
.wrapper-long {
    width: 95%;
}
.icon{
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 15px;
}
.filter-section-listing {

    background-color:#FBFCFE;
    width: 95%;
    margin:2rem auto;
    padding-top:2rem;
    padding-bottom: 3rem;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
}
.ant-input{
    font-size: 16px !important;
    color: #09101D !important;
    
}

.button-section button {
    width: 150px;
    height: 40px;
    background-color: #fff;
    border: 1px solid grey;
    font-size: 0.9rem;
    text-align: left;
    border-radius: 5px;
    padding-left: 5px;
}
.button-sectionOne button {
    width: 150px;
    height: 40px;
    background-color: #fff;
    border: 1px solid grey;
    font-size: 0.9rem;
    text-align: left;
    border-radius: 5px;
    padding-left: 5px;
    margin-left: 20px;
}
.order-download{
    display: flex;
    justify-content: flex-end;
    margin-right: -17px;
}
.download-drop{
    margin-top: 10px;
}
.download-drop span{
    margin-right: 10px;
}
.btn-primary{
    background-color: #FFFFFF !important;
    color: black !important;
}
.dropdown-toggle{
    width: 161px !important;
}
.dropdown-toggle::after{
    margin-left: 15px !important;
}
.main-section-orderlisting{
    background-color: #FFFFFF;
    width: 95%;
    margin: 2rem auto;
    border-radius: 20px;
}
.table-today{
    padding: 2rem;
    padding-bottom: 1rem;
    margin-top: 5rem;
}
.order-listing-drop{
    width: 90% !important;
    height: 48px !important;
}
.filter-icon{
    width: 33.33px;
    height: 28.58px;
    text-align: center;
}

.orderlisting-container{
    display: flex;
    flex-wrap: wrap;
    justify-self: center;
}
