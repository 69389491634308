.holidays-main-container {
    margin-top: 30px;
}

.table-head-holidays {
    background-color: #cee4ed;
}

.table-body-holidays {
    background-color: #f5f6f8;
}

.holiday-table-data-width {
    width: 27%;
}

.add-holiday-div {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.add-holiday-div p {
    color: green;
    font-weight: 700;
}

.add-holiday-main-container {
    display: flex;
    margin-top: 40px;
}

.edit-dc-submit-btn-div {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

}

.edit-dc-submit-btn-div button {
    padding: 2px 7px;
    color: white;
    background-color: #419794;
    border-radius: 5px;
}

.select-from-date-label {
    font-size: 12px;
    color: #FCB713;
    /* margin-left: 10px; */
    font-weight: 700;
}

.text-input-mgRight {
    margin-right: 20px;
}

.text-input-mgRight label{
    color: #FCB713 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.inputs-mgTop {
    margin-top: -1px;
    margin-right: 20px;
    /* margin-left: 13px; */
}

.adding-all-holiday-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
}


.holiday-list-max-height {
    max-height: 500px;
}