@import "~antd/dist/antd.css";

body {
  background-color: #F6F8FB !important;
  font-family: 'Poppins' !important;
  overflow: unset !important;
  padding: unset !important;
  width: unset !important;
}

.booked-indi {
  color: red;
  text-transform: capitalize;
}

.warehouse div {
  cursor: pointer;
}

.loading-margin {
  margin-right: 50px !important;
  margin-top: 25px !important;
}

.fnt-bld {
  font-weight: bold !important;
}

.booked-indi {
  color: red;
  text-transform: capitalize;
}

.warehouse div {
  cursor: pointer;
}

button.tabs p {
  margin-bottom: 1px;
  text-align: left;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.1px;
}

.tabs {
  width: 190px !important;
  padding-top: 0px !important;
  padding-right: 8px !important;
}

.loading-margin {
  margin-right: 50px !important;
  margin-top: 25px !important;
}

.fnt-bld {
  font-weight: bold !important;
}

.tabs:not(:last-child) {
  border: 1px solid rgba(128, 128, 128, 0.075) !important;
}

.progress-step.is-complete .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  /* background-color: #5dc732 !important; */
  background-image: url(../src/images/success.png);
  background-size: cover !important;
}

.progress-step.is-transit .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  /* background-color: #5dc732 !important; */
  background-image: url(../src/images/Transit.png);
  background-size: cover !important;
}


h6.progress-title {
  color: #9a9a9a;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  width: 150px !important;
}

.progress-text p {
  margin-bottom: 4px;
  color: #9a9a9a;
  text-align: left;
  font-weight: 300;
  font-size: 11px;
  text-transform: capitalize;
  width: 150px !important;
  overflow-y: visible;
}

.eta-style {
  color: #FCB713;
  font-weight: bold;
  margin-right: 4px;
}

.image-display {
  display: block;
  margin: auto 0 0;
  cursor: pointer;
  /* justify-content: space-between !important; */
}

.css-1xjfte8-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
  font-weight: bolder;
}

.MuiFormControl-root.MuiTextField-root.order-details-width.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 200px;
  margin: 10px;
}

.MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1480iag-MuiInputBase-root-MuiInput-root {
  font-size: 15px !important;
  color: #000000;
}

.order-tracker-progress {
  height: auto;
  background: #f6f8fb;
  /* margin-top: 20px; */
  /* margin-left: 10px !important; */
  padding: 30px 50px 60px 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  text-align: left;
  margin-bottom: 2rem;
}

.ship-dts-head {
  /* margin-left: 25px !important; */
  /* margin-left: 10px !important; */
  /* margin-right: 15px; */
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.header-font {
  font-size: 14px;
  font-weight: 700 !important;
  /* text-transform: capitalize; */
}

.driverInputNewSlot .MuiFormControl-root.MuiTextField-root.order-details-width.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: auto;
}

.mode label#standard-basic-label {
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Poppins' !important;

}

.mode1 label#standard-basic-label {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Poppins' !important;

}

.ant-modal-close-x {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-style: normal;
  line-height: 36px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  background: red;
  border-radius: 50%;
  font-weight: bolder;
  color: #ffffff;
}

/* 
label#demo-simple-select-standard-label {
  font-size: 14px !important;
  color: #09101D !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: 'Poppins' !important;

} */

.progress-tracker--vertical .progress-marker::after {
  right: auto;
  top: 12px;
  left: 14px !important;
  width: 3px !important;
  height: 100%;
}

.progress-step.is-complete .progress-marker::after,
.progress-step.is-progress .progress-marker::after {
  background-color: #000000 !important;
  /* background-color: #5dc732 !important; */
}

.progress-marker::after {
  z-index: 0 !important;
}

.bg_f6f8fb {
  background-color: #f6f8fb;
}

.text-center {
  text-align: center;
}


.stop-text {
  text-align: left;
  font-weight: 600;
}

.vl {
  border-left: 2px solid #d2d6da;
  height: 60px;
  margin: 32px 20px 0;
}

.tollgate {
  margin-top: 30px;
}

.hr-sty {
  color: #ffffff;
  height: 4px !important;
}

.clr_979796 {
  color: #979797;
}

.clr_979797 {
  color: #979797;
  margin-left: 3rem;
}

.no-slot-err {
  color: red;
  background: #80808054;
  text-align: center;
  padding: 5px;
}

.modify-selct-date {
  text-align: center;
  cursor: pointer !important;
  margin: 25px 0;
}

.slted-slot {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bolder;
  margin-top: 8px;
}

.slted-slot-time {
  font-size: 14px;
  font-weight: 500;
}

.ant-select-selector {
  background: #ffffff;
  border: 1px solid rgba(133, 140, 148, 0.2);
  box-sizing: border-box;
  border-radius: 8px !important;
  color: #6b7077;
}

input.datePicker.react-datepicker-ignore-onclickoutside {
  background: #ffffff;
  border: 1px solid rgba(133, 140, 148, 0.2);
  box-sizing: border-box;
  border-radius: 8px !important;
  color: #6b7077;
  outline: none;
}

input.datePicker {
  background: #ffffff;
  border: 1px solid rgba(133, 140, 148, 0.2);
  box-sizing: border-box;
  border-radius: 8px !important;
  color: #6b7077;
  padding-top: 5px;
}

.container {
  padding-right: 0;
}

.pad_0 {
  padding: 0;
}

input.dateVendors {
  text-align: left;
  background: #ffffff;
  /* border: 1px solid rgba(133, 140, 148, 0.2) !important; */
  border: 1px solid #6b7077 !important;
  box-sizing: border-box;
  /* border-radius: 8px !important; */
  /* color: #6b7077; */
  /* padding: 5px 10px; */
  font-size: 14px;
  /* margin-top: 5.5px; */
  /* margin-left: 10px; */
  /* margin-right: 40px; */
  outline: unset;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 200px !important;
  padding-bottom: 2.5px !important;
}

.timepicker .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.timepicker.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  animation: none;
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px !important;
  border-color: 1px solid #6b7077 !important;
}

.timepicker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 18.5px 0px 0px;
  padding-right: 0;
}

.timepicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 0px !important;
  padding-right: 14px;
  width: 197px !important;

}

.timepicker .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(0px, -1.5px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.timepicker .css-u9osun {
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding-left: -14px;
  /* padding: 0px; */
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 0px;
  top: 0px;
  margin-left: -15px;
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  user-select: none;
}

.timepicker .css-1bn53lx {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 0px;
  padding-right: 14px;
}

.timepicker .css-1uvydh2 {
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 0px 1.5px 0px;
}

.timepicker .css-igs3ac {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; */
  text-align: left;
  position: absolute;
  inset: -4px 0px 0px;
  margin: 0px;
  /* padding: 0px 8px; */
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 0px 0px 1px 0px !important;
  border-top-width: none;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.42);
  width: 2;
  /* border-width: 20px; */
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px !important;
}

.timepicker .css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: none;
  border-width: 0px;
}

[role=button],
a,
area,
button,
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

.vendor-date-blk {
  display: inline-flex;
  margin: 20px 0px;
}


.spacing-mt {
  margin-top: 10px !important;
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiTablePagination-menuItem {
  text-align: center;
}

/* .css-194a1fa-MuiSelect-select-MuiInputBase-input.MuiSelect-select{
  padding-top: 8px;
  font-family: 'Poppins' !important;

} */
.css-rmmij8 {
  padding-top: 8px !important;
  font-family: 'Poppins' !important;
}

/* .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 16px !important;
  font-weight: 600 !important;
  opacity: 0.6 !important;
  color: #09101D !important;
  font-family: 'Poppins' !important;

} */
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  color: #09101D !important;
  font-family: 'Poppins' !important;


}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-family: 'Poppins' !important;

}

label[data-shrink=false]+.MuiInputBase-formControl .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-family: 'Poppins' !important;

}

.ant-picker-input>input {
  color: #09101D !important;
  font-size: 16px !important;
  font-family: 'Poppins' !important;

}

.css-1ex1afd-MuiTableCell-root {
  font-family: 'Poppins' !important;

}

.css-1wvnaxz-MuiTableCell-root {
  font-family: 'Poppins' !important;

}

.css-1qz00cp-MuiTableCell-root {
  font-family: 'Poppins' !important;


}

.css-nmbvi2-MuiTableCell-root {
  font-family: 'Poppins' !important;

}

.ant-picker-input>input::placeholder {
  color: #09101D !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  opacity: 0.6 !important;

}

.ant-input:focus {
  border: 1px solid #419794;
  box-shadow: 0px 0px 0px 2px rgba(65, 151, 148, 0.5);
  border-radius: 6px;
}

.Toastify__toast-body {
  font-family: 'Poppins' !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Poppins' !important;

}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-family: 'Poppins' !important;


}

.css-11l4zyo-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
  font-family: 'Poppins' !important;

}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  font-family: 'Poppins' !important;

}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: 'Poppins' !important;

}

.css-pdct74-MuiTablePagination-selectLabel {
  font-family: 'Poppins' !important;

}

.css-levciy-MuiTablePagination-displayedRows {
  font-family: 'Poppins' !important;

}

/* .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
  color:#09101D !important;
  font-size:14px !important ;
  font-weight: 600 !important;
  font-family: 'Poppins' !important;

} */
.css-mqt2s5 {
  color: #09101D !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Poppins' !important;


}

.css-cktaik {

  font-size: 16px !important;
  font-weight: 600 !important;
  opacity: 0.6 !important;
  color: #09101D !important;
  font-family: 'Poppins' !important;


}

#add-label-font {
  font-size: 14px;
  color: #09101D;
  font-weight: bolder;
  line-height: 24px;
  font-family: 'Poppins' !important;

}

#table-head-font {
  font-family: 'Poppins' !important;
}

.css-1480iag-MuiInputBase-root-MuiInput-root {
  font-family: 'Poppins' !important;


}


.css-1chpzqh {
  font-family: 'Poppins' !important;
  line-height: 35px !important;
}

.css-1cccqvr.css-1cccqvr.css-1cccqvr {
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.css-348fk2 {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #09101D !important;
  opacity: 0.8 !important;
}

.css-1nrlq1o-MuiFormControl-root {
  width: 100% !important;
}

.marker-data {
  height: 85px !important;
}

.css-13sljp9 {
  width: 100% !important;
  /* top: -4px; */
}

.css-1km1ehz {
  font-family: 'Poppins' !important;


}

.css-1rcvyyg.Mui-focused {
  color: #09101D !important;
  background: #FFFFFF;
  padding-right: 2px !important;
}

.css-1rcvyyg {
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 0.8 !important;
  color: #09101D !important;
  font-family: 'Poppins' !important;
}

/* .css-1km1ehz{
  font-family: 'Poppins' !important;


} */
/* .css-348fk2.Mui-focused{
  opacity: 0.6 !important;
  color: #09101D !important;
  font-size: 16px !important;
  font-weight: 600 !important;

} */
.css-mnn31 {
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  color: #09101D !important;
  font-size: 16px !important;

}

.css-182didf {
  font-family: 'Poppins' !important;
}





.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8AD;
}

.loader {
  left: 50%;
  top: 30%;
  z-index: 1000;
  position: absolute;
}

.css-1enotpu-MuiTableRow-root:nth-of-type(odd) {
  background-color: #f6f8fb !important;
}

.all-order-details-margin-bottom {
  margin-bottom: 3rem !important;
}

/* .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
  display: none;
  background-image: url('./images/success.png');
} */


.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-icon {
  background: none;
  background-image: url('./images/success.png');
  background-size: cover;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: none;
  background-image: url('./images/success.png');
  background-size: cover;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-icon::before {
  background: none;
  background-size: cover;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: lightgray;
  border-color: none;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: lightgray;
  border-color: none;
}

.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 0px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}

/* .steps-icon-1 .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  display: none;
} */


.steps-icon-1 .ant-steps-icon {
  display: none;
  background-image: url('./images/success.png');
}


.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: black;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #f0f0f0;
}

.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  /* text-align: left; */
  margin-left: 20px;
}


.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 95%;
  margin: auto;
  font-size: 0;
  text-align: initial;
}