.container {
  width: 100% !important;
  background-color: white;
  margin: auto;
}

.container-pg-bottom {
  padding-bottom: 1rem;
}

.order-tracking {
  width: 19% !important;
  position: relative;
}



.order-details {
  display: flex;
  flex-wrap: wrap;
}

.order-detail-mg-top {
  margin-top: 20px;
  display: flex;
}


.vendor-details-main-div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.order-details-width {
  margin-left: 10px !important;
  font-size: 20px !important;
  width: 200px !important;
  font-weight: 700 !important;
  margin-right: 30px !important;
}

.vendor-inputs TextField {
  line-height: 20px;
}

.orderNo-fields {
  margin-top: 6px;
  display: flex;
}

.orderNo-fields label {
  color: #FCB713 !important;
  line-height: 32px !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}



.order-no {
  margin-left: 10px;
  line-height: 18px;
  width: 205px !important;
  position: static;
}

.address-get-order-div {
  width: 100%;
  display: flex;
}

.address-get-order-div div {
  margin-left: 10px;
  line-height: 18px;
  margin-right: 1rem;

}

.address-get-order-div div h6 {
  font-weight: 700;
  font-size: 14px;
  color: #FCB713
}

.address-get-order-div div p {
  font-size: 14px;
  font-weight: 400;
  color: #6F6E70;
}

.address-get-order-div img {
  width: 20px;
  margin-top: 10px;
}

.date_given_time {
  line-height: 45px !important;
  font-weight: 700;
  font-size: 18px;
}


.order-no h6 {
  font-weight: 700;
  font-size: 14px;
  color: #FCB713
}

.order-no p {
  font-size: 14px;
  font-weight: 400;
  color: #6F6E70;
}

.edit {
  margin-top: 10px;
}

.editOrder {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.editOrder img {
  width: 34px;
  height: 45px;
}

.editOrder p {
  color: #358481;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
}

.vendor-details-in-get-order {
  margin-top: 18px;
  margin-left: 10px;
}

.vendor-details-in-get-order h6 {
  font-size: 18px;
  font-weight: 700;
}

.vendor-details {
  margin-top: 18px;
}

.vendor-details h6 {
  font-size: 18px;
  font-weight: 700;
}

.view-vendor {
  margin-left: 10px;
}

.view-vendor h6 {
  font-weight: 700;
  width: 257px;
  font-size: 16px;
  letter-spacing: 0.1px !important;
  line-height: 18px !important;
}

.view-vendor span {
  font-weight: 400;
}

.fonts {
  width: 134px;
  font-weight: 700;
  font-size: 19px;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.pointer {
  cursor: pointer;
}

.vendor-inputs label {
  font-weight: 700 !important;
  line-height: 35px !important;
  width: 197px;
  color: #FCB713 !important;
}

.vehicle-details {
  background-color: #f6f8fb;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 20%);
  background-color: #f6f8fb;
}

.truck-details-div {
  margin-left: 3rem;
}

.truck-details-container-div {
  justify-content: space-around;
  padding: 10px;
}

.track-vehicle {
  display: flex;
  margin: 10px 0px;
}

.deliveryTruck {
  width: 67px;
  height: 50px;
}

.deliveryTruck1 {
  width: 67px;
  height: 67px;
}

.deliveryTruck2 {
  width: 68px;
  height: 69px;
}

.deliveryTruck3 {
  width: 45px;
  height: 46px;
}

.vehicle-input {
  margin-left: 20px !important;
}

.truck-type {
  line-height: 7px;
  padding: 5px;
  padding-top: 10px;
}

.vehicle-type {
  font-weight: 500;
  color: #09101D;
  font-size: 14px;
}

.bolder {
  font-weight: 700;
  font-size: 14px;
}

.date-orders {
  color: #419794;
  font-size: 21px;
  font-weight: 700;
}

.month {
  font-weight: 500;
  font-size: 14px;
}

.factory-address-p {
  white-space: normal;
  line-height: 16px;
}

.receiver {
  margin-top: 30px !important;
  background-color: #ffffff;
  font-size: 18px !important;
  font-weight: 700;
  box-shadow: 0px 5px 5px rgb(255 255 255/25%) !important;
}

.receiver label {
  font-weight: 700 !important;
  line-height: 32px !important;
  width: 197px;
  color: #FCB713 !important;
}

.receiver h5 {
  margin-top: 20px;
  margin-left: 10px;
}



.shipment {
  background-color: #f6f8fb !important;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  justify-content: space-around;
}

.shipping {
  background-color: #f6f8fb !important;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

}

.shipFrom {
  display: flex;
}

.shipFrom img {
  margin-right: 5px;
  width: 15px;
  height: 20px;
}

.ships {
  font-weight: 600;
  font-size: 14px;
}

.vahan-update {
  background-color: #f6f8fb;
  width: 95% !important;
  height: auto;
  margin: 20px 15px;
  margin-left: -0px !important;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}


.vahan-header {
  display: flex;
  flex-direction: end;
  padding: 20px;
  justify-content: space-between;
  padding-bottom: 0px;
}


.vahan-edit-update {
  padding: 6px 15px;
  border: none;
  background: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  color: #16c612;
  width: 110px;
  font-weight: 500;
}

.vahan-edit-update span {
  font-size: 14px;
}

.vahan-header-status {
  display: flex;
  justify-content: space-around;
  padding-left: 65px;
}

.stops {
  display: flex;
}

.tollgate {
  text-align: center;
}

.editButton {
  height: 40px;
  width: 130px;
}

.editing {
  height: 400px;
  background: #ffffff;
}

.edit-modify-order-inputs {
  width: 200px;
  margin-right: 2rem;
}

.edit-modify-order-inputs .css-s168vh {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  padding: 0px;
  margin: 0px 0px 0px 0px;
  border: 0px;
  vertical-align: top;
  min-width: 200px;
}

.mode {
  background-color: #d8d8d8;
  height: 80px;
  padding: 10px;
  text-align: center;
  margin-top: 5px;
  display: flex;
}

.edit-order-no {
  margin-left: 2rem;
  line-height: 18px;
  position: static;
  justify-content: space-around;
  width: 175px;
  height: 48px;
  align-items: flex-start;
}

.edit-order-no h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #19181a;
  text-align: left
}

.edit-order-no p {
  font-weight: normal;
  font-size: 14px;
  align-items: flex-start !important;
  color: #6f6e70;
  text-align: left;
}

.mode1 {
  display: flex;
  padding: 10px;
  text-align: center;

}

.mode1-details {
  margin-left: 2rem;
  line-height: 18px;
  position: static;
  justify-content: space-around;
  width: 175px;
  height: 48px;
  align-items: flex-start;
}

.mode1-details h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #19181a;
  text-align: left
}

.mode1-details p {
  font-weight: normal;
  font-size: 14px;
  align-items: flex-start !important;
  color: #6f6e70;
  text-align: left;
}

.mode1-details span {
  font-weight: normal;
  font-size: 14px;
  align-items: flex-start !important;
  color: #6f6e70;
}

.mode2 {
  margin-top: 2.3rem;
}

.mode2 h5 {
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.selectDate {
  margin-top: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 24px;
  color: #19181a;
}

.edit-dropdowns {
  margin-top: 17px;
  display: inherit;
  margin-left: 20px;
}

.footer {
  background-color: #000000;
}

.updatedMessage {
  margin-top: 40px;
  margin-left: 35px;
}

.updatedMessage p {
  color: #DA2222;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.vahan-download-invoice {
  padding: 7px 0px;
  border: none;
  background: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  color: #16c612 !important;
  width: 185px;
  font-size: 13px;
  height: 35px !important;
  text-align: center;
}

.update-hints {
  text-align: left;
  background-color: #d8d8d8;
  width: 550px !important;
  margin: auto;
  margin-bottom: 20px !important;
  padding-left: 10px;
}

.update-hints span {
  font-weight: bold;
  color: green;
}

.stops-update-submit-btn {
  margin-left: 10px;
  height: 26px;
}

.stops-update {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.actual-arrival {
  font-weight: 700;
  margin-right: 3px;

}

.actual-date-time {
  line-height: 10px;
}

.green-color-image {
  background-image: url(../../images/success.png);
}

.vahan-arrival-update {
  margin-bottom: 10px;
  margin-left: 3rem;
}

.vahan-arrival-calendar {
  padding: 5px;
  border: 1px solid black;
  width: 300px !important;
  text-align: center;
  border-radius: 5px;
  padding-left: 10px;
  /* border-style: none; */
  background: #F6F8FB;
  cursor: pointer;
}

.vahan-arrival-button {
  height: 34px;
  padding: 5px;
}

.vahan-ontime {
  color: rgb(75, 224, 75);
  font-weight: bold;
}

.vahan-delay {
  color: red !important;
  font-weight: bold;
}

.redDot {
  width: 100px;
  height: 100px;
  background: red;
  border-radius: 50%
}


.vahan-early {
  color: #FCB713;
  font-weight: bold;
}

.bold {
  font-weight: 700;
}

.green-dot {
  margin-right: 10px;
}

.toll-reached {
  width: 25px;
  margin-right: 10px;
}

.filter-status {
  cursor: pointer;
  color: red;
  padding-top: 5px;
}

.vendor-dc-shipped-details {
  padding: 10px;
}

.shipment-messages {
  display: flex;
}

.checking {
  background-color: black;
  color: white;
}

.get-order-messages {
  width: 99%;
  background-color: #f6f8fb;
  overflow-y: scroll;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  height: 425px;
}

.shipment-details-width {
  width: 100%;
  display: flex;
}

.shipment-details-data {
  margin-top: -20px;
  margin-right: 20px;
  width: 19%;
  height: 80%;

}

.message-box {
  width: 75%;
  float: right;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;
}

.delivery-comments-top-image {
  display: flex;
  justify-content: center;
}

.delivery-comments-top-image img {
  width: 25px;
  height: 30px;
  margin-right: 10px;
}

.delivery-comments-top-image p {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
}

.comments-box {
  width: 90%;
  height: 200px;
  background-color: #ffffff;
  margin: 20px;
  border-style: none;
  padding: 5px;
  opacity: 0.9;
  position: relative;
  resize: none;
}

textarea:focus,
input:focus {
  outline: 0;
}

.comment-submit-button {
  margin-right: 40px !important;
  height: 60px;
}

.user {
  display: flex;
  line-height: 10px;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  position: relative;
}

.user-name-messages {
  float: left !important;
}

.user-timeOnly {
  float: right !important;
  padding-top: 5px;
}

.userName {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #419794;
  width: 400px !important;
}

.user-messages {
  font-size: 14px;
  line-height: 10px;
  color: #000000;
  line-height: 20px;
}

.user-time {
  font-weight: 500;
  font-size: 14px;
  color: #FCB713;
  width: 400px !important;
  white-space: nowrap !important;
}

.time-padding {
  padding-right: 5px;
}

.hr-tag {
  border: 2px solid #FFFFFF;
  transform: rotate(-0.27deg);
}

.expected-time {
  font-size: 13px;
}

.textarea {
  resize: none;
}

.ship-msg-height-only {
  max-height: 50% !important;
}


.expected-time {
  color: #FCB713;
  font-weight: 600;
  text-align: center;
}

.tracking-heading {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
}


.track-live-location-div {
  border-radius: 10px;
  display: flex;
  padding: 6px 0;
  width: 230px;
  border: none;
  background-color: #419794;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.track-live-location-div p {
  padding: 0 10px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 16px;
}

.track-live-location-div a {
  padding: 0 10px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 16px;
}

.input-btn-container {
  display: flex;
  margin-bottom: 10px;
}

.input-btn-container input {
  border-radius: 5px;
  border: 1.2px solid #419794;
  border-radius: 2.5rem;
  width: 100%;
  padding-left: 15px;
}

.input-btn-container button {
  border-radius: 5px;
  font-size: 25px;
  border-radius: 2.5rem;
  margin-left: -3rem;
  background-color: #419794;
  width: 3rem;
  color: white;
  cursor: pointer;
}

.shipment-details-main-container {
  margin-left: 10px;
  width: 97%;
  margin-bottom: 3rem;
}

.tracking-details-main-container {
  margin-left: 10px;
  width: 97%;
  margin-bottom: 3rem;
}

.horizontal-tracking-div {
  margin-left: 10px;

}

.truck-tracking-map {
  width: 95%;
  height: 800px;
}

.track-live-location-iframe {
  margin-left: 10px;
  margin-bottom: 20px;
}

.modify-order {
  display: flex;
}
.modify-order-datepicker {
  width: 200px;
  margin-right: 2rem;
}
.modify-order-datepicker p {
  margin-bottom: 0px;
  margin-bottom: -4px;
  font-weight: 400;
  font-size: 11px;
  font-weight: 700;
  opacity: 0.8;
  color: #09101d !important
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -87px !important;
}


.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -85px !important;
}

.vendor-address-shipment {
  width: 250px;
}
.ant-tooltip-inner {
  width: fit-content;
}

@media (min-width:1024px) and (max-width:1380px) {
  .shipment {
    background-color: #f6f8fb !important;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    display: flex;
    justify-content: space-around;
  }

  .slot-to-mt {
    margin-top: 10px !important;
  }

  .upload-arrow-arrival-btn {
    margin-left: 87px;
  }

  .scheduled-arrival-width {
    width: 248px !important;
  }

  .order-details-width {
    margin-left: 10px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-right: 30px !important;
  }

  .receiver {
    margin-top: 30px !important;
    font-size: 18px !important;
    font-weight: 700;
    box-shadow: 0px 5px 5px rgb(255 255 255/25%) !important;
  }

  .vehicle-input {
    margin-left: 6px !important;
  }
  .vendor-detail-mg-top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .view-invoice-div {
    margin-top: 10px;
    margin-left: 10px;
  }
  .vahan-download-invoice {
    padding: 7px 0px;
    border: none;
    background: #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    color: #16c612 !important;
    margin-top: 20px !important;
    width: 185px;
    font-size: 13px;
    height: 35px !important;
    text-align: center;
  }

}

.iframes {
  border: 0;
  height: 0px;
  width: 0px;
}

.vehicle-details-p {
  font-size: 16px;
  font-weight: 700;
  color: #FCB713;
  /* margin-bottom: 20px; */
}


.truck-alignment {
  display: flex;
  align-items: center;
}

.truck-marginBottom {
  padding-bottom: 18px
}

.order-delivered-eta {
  font-size: 16px;
  font-weight: 600;
  color: #419794;
}

.red-color {
  color: red;
  font-size: 14px;
  font-weight: 700;
}

.green-color {
  color: green;
  font-size: 14px;
  font-weight: 700;
}

.orange-color {
  color: orange;
  font-size: 14px;
  font-weight: 700;
}

.minus-colors {
  color: red;
  font-size: 16px;
  font-weight: 700;
}