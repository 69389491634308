.progress-tracker {
  display: flex;
  padding: 30px 60px 20px 20px;
  padding: 0;
  list-style: none;
  text-align: center;
}

.progress-step {
  flex: 1 1 0%;
  margin: 0;
  padding: 0;
  min-width: 40px;
}
.progress-step:last-child {
  flex-grow: 0;
}
.progress-step:last-child .progress-marker::after {
  display: none;
}

.progress-link {
  display: block;
  position: relative;
}

.progress-marker {
  display: block;
  position: relative;
  height: 85px !important;
}
.progress-marker::before {
  content: attr(data-text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 30px;
  height: 30px;
  padding-bottom: 2px;
  border-radius: 50%;
  transition: background-color, border-color;
  transition-duration: 0.3s;
}

.progress-marker::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -10;
  top: 10px;
  right: -12px;
  width: 100%;
  height: 4px;
  transition: background-color 0.3s, background-position 0.3s;
}

.progress-text {
  display: block;
  padding: 10px 8px;
  text-overflow: ellipsis;
  line-height: 13px;
}

.progress-title {
  margin-top: 0;
}

.progress-step .progress-marker {
  color: #fff;
}
.progress-step .progress-marker::before {
  background-color: #b6b6b6;
}
.progress-step .progress-marker::after {
  background-color: #b6b6b6;
}
.progress-step .progress-text {
  color: #333;
}
.progress-step.is-active .progress-marker::before {
  background-color: green;
}
.progress-step.is-complete .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  background-color: green;
}
.progress-step.is-complete .progress-marker::after,
.progress-step.is-progress .progress-marker::after {
  background-color: #868686;
}
.progress-step.is-progress-10 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 10%, #b6b6b6 10%);
}
.progress-step.is-progress-20 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 20%, #b6b6b6 20%);
}
.progress-step.is-progress-30 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 30%, #b6b6b6 30%);
}
.progress-step.is-progress-40 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 40%, #b6b6b6 40%);
}
.progress-step.is-progress-50 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 50%, #b6b6b6 50%);
}
.progress-step.is-progress-60 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 60%, #b6b6b6 60%);
}
.progress-step.is-progress-70 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 70%, #b6b6b6 70%);
}
.progress-step.is-progress-80 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 80%, #b6b6b6 80%);
}
.progress-step.is-progress-90 .progress-marker::after {
  background-image: linear-gradient(to right, #868686 90%, #b6b6b6 90%);
}
.progress-tracker--text .progress-step:last-child,
.progress-tracker--center .progress-step:last-child,
.progress-tracker--right .progress-step:last-child {
  flex-grow: 1;
}

.progress-tracker--center {
  text-align: center;
}
.progress-tracker--center .progress-marker::before,
.progress-tracker--center .progress-text--dotted::before {
  margin-left: auto;
  margin-right: auto;
}
.progress-tracker--center .progress-marker::after {
  right: -50%;
}

.progress-tracker--right {
  text-align: right;
}
.progress-tracker--right .progress-marker::before,
.progress-tracker--right .progress-text--dotted::before {
  margin-left: auto;
}
.progress-tracker--right .progress-marker::after {
  right: calc(-100% + 12px);
}

.progress-tracker--spaced .progress-marker::after {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

.progress-tracker--border {
  padding: 4px;
  border: 2px solid #333;
  border-radius: 32px;
}

.progress-tracker--theme-red .progress-step .progress-marker {
  color: #fff;
}
.progress-tracker--theme-red .progress-step .progress-marker::before {
  background-color: #666;
}
.progress-tracker--theme-red .progress-step .progress-marker::after {
  background-color: #666;
}
.progress-tracker--theme-red .progress-step .progress-text {
  color: #333;
}
.progress-tracker--theme-red .progress-step.is-active .progress-marker::before {
  background-color: #a62d24;
}
.progress-tracker--theme-red
  .progress-step.is-complete
  .progress-marker::before {
  background-color: #d93b30;
}
.progress-tracker--theme-red
  .progress-step.is-complete
  .progress-marker::after {
  background-color: #333;
}
.progress-tracker--theme-red .progress-step:hover .progress-marker::before {
  background-color: #df7b74;
}
.progress-text--dotted::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin: 6px -2px;
  background-size: 12px 18px;
  background-image: repeating-radial-gradient(
    circle at center 6px,
    #b6b6b6,
    #b6b6b6 5px,
    rgba(182, 182, 182, 0.5) 5.5px,
    rgba(182, 182, 182, 0.01) 6px,
    transparent 100%
  );
}

.progress-text--dotted-1::before {
  height: 12px;
}

.progress-text--dotted-2::before {
  height: 30px;
}

.progress-text--dotted-3::before {
  height: 48px;
}

.progress-text--dotted-4::before {
  height: 66px;
}

.progress-text--dotted-5::before {
  height: 84px;
}

.progress-text--dotted-6::before {
  height: 102px;
}

.progress-text--dotted-7::before {
  height: 120px;
}

.progress-text--dotted-8::before {
  height: 138px;
}

.progress-text--dotted-9::before {
  height: 156px;
}

.progress-text--dotted-10::before {
  height: 174px;
}

.progress-text--dotted-11::before {
  height: 192px;
}

.progress-text--dotted-12::before {
  height: 210px;
}

.progress-tracker--text-top .progress-text {
  height: 100%;
}
.progress-tracker--text-top .progress-marker {
  top: -24px;
}

.progress-tracker--text-inline {
  overflow: hidden;
}
.progress-tracker--text-inline .progress-step,
.progress-tracker--text-inline .progress-marker {
  display: flex;
  align-items: center;
}
.progress-tracker--text-inline .progress-marker {
  flex-grow: 1;
}
.progress-tracker--text-inline .progress-marker::after {
  top: auto;
}
.progress-tracker--text-inline .progress-text {
  position: relative;
  z-index: 30;
  max-width: 70%;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
}
.progress-tracker--text-inline .progress-marker .progress-text {
  display: inline-block;
}
.progress-tracker--text-inline .progress-title {
  margin: 0;
}

.progress-tracker--square .progress-marker::before {
  border-radius: 0;
}
.progress-tracker--square .progress-marker::after {
  top: auto;
  bottom: 0;
}

@media (max-width: 575px) {
  .progress-tracker-wrapper {
    overflow-x: auto;
    scroll-snap-type: x proximity;
  }
  .progress-tracker-wrapper .progress-step {
    min-width: 50%;
    scroll-snap-align: start;
  }
}
.progress-tracker--vertical {
  flex-direction: column;
}
.progress-tracker--vertical .progress-step {
  display: flex;
  flex: 1 1 auto;
}
.progress-tracker--vertical.progress-tracker--right .progress-step {
  flex-direction: row-reverse;
}
.progress-tracker--vertical .progress-marker::after {
  right: auto;
  top: 12px;
  left: 10px;
  width: 4px;
  height: 100%;
}
.progress-tracker--vertical .progress-text {
  padding: 0 12px 24px 12px;
}

.progress-step.is-complete .anim--path .progress-marker::after {
  background-position: -100% 100%;
}
.anim--path .progress-step.is-complete .progress-marker::after {
  background-position: -100% 100%;
}

[dir="rtl"] .progress-marker::after {
  right: auto;
  left: -12px;
}
[dir="rtl"] .progress-tracker--center .progress-marker::after {
  left: -50%;
}

.text-transit {
  border-radius: none !important;
  width: 140px !important;
  float: left;
}