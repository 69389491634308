.add-input {
    margin: 2% 7% 2% 7%;
    background: white;
    padding: 2.5% 3% 3% 3%;
    border-radius: 10px;
}


.dc-flex {
    display: flex;
    justify-content: center;
}

.dc-flex-margin {
    margin: 20px;
}

.dc-flex-margin1 {
    margin-left: 190px;
    margin-top: 40px;
    min-width: 400px;
}


.dc-textfield-width {
    width: 100% !important;
}

.dc-margin {
    margin-bottom: 2rem;
}

.button-dc-submit {
    width: 300px !important;
    text-align: center !important;
    font-family: 'Poppins' !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    /* margin: 44px !important; */
    margin-top: 30px !important;
}

.edit-dc-name-div {
    text-align: center;
    margin-bottom: 2rem;
}

.edit-dc-name-div h2 {
    font-weight: 500;
}

.dc-address-map-icon-div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.dc-address-map-icon-div img {
    width: 30px;
    height: auto;
    position: absolute;
}