.dashboard-top{
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
}
.each-block{
    
    display: flex;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
    padding: 21px;
    margin: 10px;
    height: 90px;
    width: 250px;

}
.each-block h6 img{
    width: 45px;
    height: 45px;
}
.nested-block{
    padding-left: 20px;
}
.nested-block p{
    margin: 0px;
   font-size: 16px;
   font-weight: 600;
   text-align: center;
letter-spacing: 0.3px;
   color: #419794;
   line-height: 20px;
}
.nested-block p span{
    color: #419794;
    font-weight: 400;
font-size: 18px;
line-height: 30px;
letter-spacing: 0.1px;

}
.nested-block-one{
    padding-left: 20px;


}
.nested-block-one p{
    margin: 0px;
    font-size: 16px;
   font-weight: 600;
   text-align: center;
letter-spacing: 0.3px;
   color: #FB881E;
   line-height: 20px;

}
.nested-block-one p span{
    color: #FB881E;
    font-size: 18px;
line-height: 30px;
font-weight: 400;

letter-spacing: 0.1px;
}

.nested-block-two{
    padding-left: 20px;


}
.nested-block-two p{
    margin: 0px;
    font-size: 16px;
   font-weight: 600;
   text-align: center;
letter-spacing: 0.3px;
line-height: 20px;

   color: #124999;
}
.nested-block-two p span{
    color: #124999;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;

    letter-spacing: 0.1px;
}

.nested-block-three{
    padding-left: 13px;


}
.nested-block-three p{
    margin: 0px;
    font-weight: 600;
   text-align: center;
letter-spacing: 0.3px;
font-size: 16px;

line-height: 20px;
   color: #E7BE43;
}
.nested-block-three p span{
    color: #E7BE43;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;

    letter-spacing: 0.1px;
}


.table-containers{
    display: flex;
    justify-content: space-around;
     margin: 10px;   
}
.table-left{
    width: 48%;
    margin-top: 0px;
    background: #FFFFFF;
    padding: 12px;
    border-radius: 5px;
}
.table-right{
    width: 48%;
    background: #FFFFFF;
    padding: 12px;
    border-radius: 5px;
}
.table-head-dashboard{
 background-color:#E7E7ED !important;
color: #262727 !important;
font-size: 0.85rem !important;
font-weight: 700 !important;
    
}
.vendor-orders{
    background-color: #fff;
    margin: 0px;
    padding: 10px 10px;
    border-radius: 5px;
}
.vendor-orders h6{
    text-align: center;
    font-weight: 700;
   color: #419794;
    
}

.dashboard-track-my-fleet {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
}