.dc-heading{
    background-color: #419794;
    text-align: center;
  
   
}
.dc-heading h4{
    color:#ffff;
    padding: 5px 0px;

}
.filter-section{
    background-color:#FBFCFE;
   margin:2rem 0rem;
   width: 95%;
padding-top:2rem;
padding-bottom: 3rem;
padding-left: 20px;
padding-right: 20px;
    border-radius: 20px;
    height: 140px;
}
.filter-top{
    margin-top: -15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
   
}
.filter-top h6{
    margin-top: -10px;
    margin-bottom: -13px;
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: 700;
    color:#000000C4;
    text-align: center;
}
.filter-top p{
    margin-top: -10px;
    color: #419794;
    margin-bottom: -13px;
    margin-right: 0.8rem;
    font-size: 1rem;
    font-weight: 500;
}
.filter-dropdown{
    display: flex;
    flex-wrap: wrap;
}
.state-dropdown{
    display: flex;
    margin-bottom: 1.5rem;
    width: 100%;
    justify-content: left;
}

.button{
    background-color: #ffff;
    font-size: 0.8rem;
    color: black;
    border: 1px solid grey;
    width: 170px;
    text-align: left;
   
}



.each-dropdown{
    margin-left: 18px;
    
}

.search{
    height: 42px !important;
    border-radius: 5px;
    padding-right: 45px;
    /* margin-top: 6px; */

}
.icon-search{
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 15px;
}

.middle-section{
    display: flex;
    justify-content: space-between;
    margin:0px 2rem;
}

.download{
    margin-bottom: 0.7rem;
}
.download a{
    color:#262727 ;
    margin-left: 2rem;
    margin-right: 0.5rem;
    font-weight: 700;
    font-size: 0.8rem;

    
}
.download a:hover{
    color:#262727;
    
}
.table-container{
   
padding-top:2rem;
padding-bottom: 3rem;
padding-left: 20px ;
padding-right: 20px;
margin-top: -22px !important;

}
.table-head{
    background-color:#E7E7ED !important;
    color: #262727 !important;
    font-size: 0.9rem !important;
    font-weight: 700 !important;
}
.pagination{
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center  !important;
}
.main-section-dc{
    background-color: #FFFFFF;
    width: 95%;
    border-radius: 20px;
}

.dc-management-main-div {
    margin-top: 3rem;
}
.add-dc-section{
    display: flex;
    margin-top: 20px;
    cursor: pointer;
}
.add-dc-section p{
    color: #419794;
    font-size: 14px;
    font-weight: 700;
    margin-left: 6px;
    margin-top: 3px;
    
}
.add-dc-section img{
    width: 34px;
    height: 34px;
    margin-left: -10px;
}
.dc-download{
    /* margin-top: 30px; */
    margin-right: -49px;
}
.middle-section-working{
    display: flex;
    justify-content: flex-end;
    margin:0px 2rem;
    margin-top: 2rem;

}
.main-section-dc-working{
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 20px;
    margin-top: 5rem;

}
.dc-drop{
    width: 90% !important;
    height: 48px !important;
}
.dc-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

