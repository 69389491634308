.edit-icon-img {
    width: 23.9px;
    height: 23.9px;
}

.addDriverIcon {
    cursor: pointer;
}



.css-1qz00cp-MuiTableCell-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
}

.edit-heading-div{
    text-align: center;
    margin: 10px 0px;
}

.edit-heading-div h2 {
    font-weight: 600;
}

.edit-heading-div  p {
    margin-right: 2rem;
    font-size: 16px;
    margin-bottom: 0px !important;
    font-weight: 500;
}

.driver-edit-container {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 10px;
}

.driver_name {
    margin-right: 30px;
}

.driver_name label {
    color: #FCB713;
}


.driver-add-container {
    display: flex;
    padding: 10px;
    margin-left: 5px;
}

.driver_contact {
    margin-right: 15px !important;
}

.driver_contact label {
    color: #FCB713;
}

.add-driver-container {
    margin-right: 10px;
}

.add-driver-container button {
    padding: 3px 18px;
    border-radius: 5px;
    margin-top: 15px;
    color: white;
    background-color: green;
}

.scrolling {
    overflow: scroll;
}

.table-scroll {
    max-height: 230px;
}