.all-fields-password-container {
    /* align-items: center;
    display: flex;
    justify-content: center;
    text-align: left; */
}

.email-reset-password-main-div {
    width: 45%;
    padding: 32px;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.name-inputs-div {
    margin-bottom: 20px;
}

.name-inputs-div p {
    margin-bottom: 0;
    font-weight: 500;
}

.new-passowrd-inputs {
    width: 95%;
}


.name-inputs-div button {
    background-color: green;
    color: white;
    justify-content: center;
    display: flex;
    margin-top: 20px !important;
    margin: auto;
    padding: 3px 10px;
    border-radius: 5px;
}

.reset-password {
    text-align: center;
    margin-bottom: 2rem;
}


.apollo-logos {
    /* margin-top: 6rem; */
    text-align: center;
    padding-top: 3rem;
    margin-top: -2rem;
}

.brand-logo-new-password {
   width: 60%;
   margin-bottom: 10px;
}

.brand-logo-new-password img {
    width: 90px !important;    
}


.loginName-new-password {

}

.loginName-new-password h1 {
    font-size: 1.3rem;
    font-weight: 1000;
    color: #FCB713;
    font-family: 'Rammetto One';
    font-weight: 400;
    letter-spacing: 0.1rem;
}

.loginName-new-password span {
    color: #419794
}

.eye-icon {
    margin-left: 10px;
    vertical-align: text-bottom;
}

@media (min-width:1024px) and (max-width:1380px) { 
    .apollo-logos {
        text-align: center;
        padding-top: 2.5rem;
        margin-bottom: 0rem !important;
    }

}

@media (min-width:1600px) and (max-width:2700px) { 
    .apollo-logos {
        text-align: center;
        padding-top: 9.5rem;
        margin-bottom: 0rem !important;
    }

}